import React from "react"
import styles from "./Example.module.css"

function Example({ className, children }) {
  return (
    <div className={`border border-blue-500 rounded p-4 ${styles.wrapper} ${className}`}>
      {children}
    </div>
  )
}

export default Example
