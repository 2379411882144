import CardBase from "../../../../content/blog/tailwind-card/Examples/CardBase";
import CardWithGradientRounded from "../../../../content/blog/tailwind-card/Examples/CardWithGradientRounded";
import Gradient from "../../../../content/blog/tailwind-card/Examples/Gradient";
import CardWithGradient from "../../../../content/blog/tailwind-card/Examples/CardWithGradient";
import CardWithGradientRoundedFailedWithOverflow from "../../../../content/blog/tailwind-card/Examples/CardWithGradientRoundedFailedWithOverflow";
import CardWithAvatar from "../../../../content/blog/tailwind-card/Examples/CardWithAvatar";
import CardWithAvatarOrganized from "../../../../content/blog/tailwind-card/Examples/CardWithAvatarOrganized";
import CardWithQuote from "../../../../content/blog/tailwind-card/Examples/CardWithQuote";
import CardWithQuoteIcon from "../../../../content/blog/tailwind-card/Examples/CardWithQuoteIcon";
import CardWithTwitter from "../../../../content/blog/tailwind-card/Examples/CardWithTwitter";
import CardWithAnimation from "../../../../content/blog/tailwind-card/Examples/CardWithAnimation";
import Quote from "../../../../content/blog/tailwind-card/Examples/quote.inline.svg";
import * as React from 'react';
export default {
  CardBase,
  CardWithGradientRounded,
  Gradient,
  CardWithGradient,
  CardWithGradientRoundedFailedWithOverflow,
  CardWithAvatar,
  CardWithAvatarOrganized,
  CardWithQuote,
  CardWithQuoteIcon,
  CardWithTwitter,
  CardWithAnimation,
  Quote,
  React
};