import React from "react"
import logo from "./caniuse-logo.png"
import w3cLogo from "./w3c-logo.png"
import MDNLogo from "./mdn-logo.jpeg"
import LightBulb from "./LightBulb"

function CanIUse({ docURL, name, support, canIUseURL, w3cURL }) {
  return (
    <div className="rounded p-4 bg-blue-50">
      <p
        className="font-bold flex"
        style={{ fontWeight: "700", margin: "0 0 0.5rem 0" }}
      >
        <LightBulb />
        &nbsp; {name}
      </p>
      <ul className="leading-8">
        {canIUseURL && (
          <li>
            <a
              href={canIUseURL}
              target="_blank"
              rel="noreferrer"
              className="link-green"
            >
              <img
                src={logo}
                alt="caniuse.com logo"
                className="w-6 h-6 mr-2 inline"
              />
              Global Support: {support}
            </a>
          </li>
        )}
        {docURL && (
          <li>
            <a
              href={docURL}
              target="_blank"
              rel="noreferrer"
              className="link-blue"
            >
              <img
                src={MDNLogo}
                alt="Mozilla Developer Network logo"
                className="h-6 mr-2 inline"
              />
              MDN Docs
            </a>
          </li>
        )}
        {w3cURL && (
          <li>
            <a
              href={w3cURL}
              target="_blank"
              rel="noreferrer"
              className="link-blue"
            >
              <img
                src={w3cLogo}
                alt="W3c logo"
                className="w-6 h-6 mr-2 inline"
              />
              W3C
            </a>
          </li>
        )}
      </ul>
    </div>
  )
}

export default CanIUse
