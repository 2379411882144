import OutsideVariable from "../../../../content/blog/a-complete-guide-to-useref/Examples/OutsideVariable";
import OutsideVariableMultiple from "../../../../content/blog/a-complete-guide-to-useref/Examples/OutsideVariable/multiple";
import RefButton from "../../../../content/blog/a-complete-guide-to-useref/Examples/Buttons/RefButton";
import AttachingToDomExample from "../../../../content/blog/a-complete-guide-to-useref/Examples/AttachingToDomExample";
import Chat from "../../../../content/blog/Chat";
import Alert from "../../../../content/components/Alert";
import InteractiveExample from "../../../../content/blog/InteractiveExample";
import Warning from "../../../../content/components/Warning";
import UsePreviousExample from "../../../../content/blog/a-complete-guide-to-useref/Examples/UsePreviousExample";
import UseCallbackExample from "../../../../content/blog/a-complete-guide-to-useref/Examples/UseCallbackExample";
import * as React from 'react';
export default {
  OutsideVariable,
  OutsideVariableMultiple,
  RefButton,
  AttachingToDomExample,
  Chat,
  Alert,
  InteractiveExample,
  Warning,
  UsePreviousExample,
  UseCallbackExample,
  React
};