import FirstExample from "../../../../content/blog/adjacent-sibling-combinator/Examples/First";
import MarginExample from "../../../../content/blog/adjacent-sibling-combinator/Examples/Margin";
import MarginExampleSolution from "../../../../content/blog/adjacent-sibling-combinator/Examples/MarginSolution";
import MarginGenericSolution from "../../../../content/blog/adjacent-sibling-combinator/Examples/MarginGenericSolution";
import CanIUse from "../../../../content/components/CanIUse";
import * as React from 'react';
export default {
  FirstExample,
  MarginExample,
  MarginExampleSolution,
  MarginGenericSolution,
  CanIUse,
  React
};