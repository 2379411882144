// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
//import "prismjs/themes/prism.css"

//import "./prism-vsc-dark-plus.css"
//import "./prism-dracula.css"
//import "./prism-vs.css"
//import "./prism-ghcolors.css"
//import "./prism-synthwave84.css"
import "./prism-material-light.css"
