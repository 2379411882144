import React from 'react'

function Gradient () {
  return (
    <div className='bg-gradient-to-r from-green-400 to-blue-500'>
      Hi
    </div>
  )
}

export default Gradient
