import React from "react"

function BoxExample() {
  const borderWidth = 10

  return (
    <div>
      <div className="bg-blue-500 rounded text-white">Hi</div>
    </div>
  )
}

export default BoxExample
