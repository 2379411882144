import Base from "../../../../content/blog/polyfill-flex-gap/Base";
import SpaceBetweenItemsExample from "../../../../content/blog/polyfill-flex-gap/SpaceBetweenItemsExample";
import MarginExample from "../../../../content/blog/polyfill-flex-gap/MarginExample";
import MarginExampleWorking from "../../../../content/blog/polyfill-flex-gap/MarginExampleWorking";
import NestingExample from "../../../../content/blog/polyfill-flex-gap/NestingExample";
import BorderExample from "../../../../content/blog/polyfill-flex-gap/BorderExample";
import CanIUse from "../../../../content/components/CanIUse";
import CSSVariablesExample from "../../../../content/blog/polyfill-flex-gap/CSSVariablesExample";
import GapExample from "../../../../content/blog/polyfill-flex-gap/GapExample";
import * as React from 'react';
export default {
  Base,
  SpaceBetweenItemsExample,
  MarginExample,
  MarginExampleWorking,
  NestingExample,
  BorderExample,
  CanIUse,
  CSSVariablesExample,
  GapExample,
  React
};